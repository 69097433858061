<template>
  <autoadjusting-row v-if="catsAndTagsLoaded">
    <v-col class="pt-10 pb-16">
      <text-component class="pb-2 pb-sm-4" centered darkThemed>
        <template #titleh2>{{ $t('start.project.teaserTitle') }} </template>
      </text-component>
      <v-sheet class="text-center pb-5">
        <v-btn plain color="black" :ripple="false" class="text-body-1"
          :class="categoriesActive ? 'font-weight-bold' : 'font-weight-light'" @click="showCategories(true)">{{
            $t('start.project.teaserCategory') }}
        </v-btn>
        <v-divider class="d-inline" vertical role="presentation" />
        <v-btn plain color="black" :ripple="false" :class="servicesActive ? 'font-weight-bold' : 'font-weight-light'"
          @click="showCategories(false)">
          {{ $t('start.project.teaserServices') }}</v-btn>
      </v-sheet>
      <div class="text-center pb-5 pb-sm-9">
        <v-chip v-for="tag in activeTags" :key="tag.id" class="mt-2 ml-2 rounded-sm white--text text-body-2"
          color="purple" :style="getStyle" @click="setTagById(tag.id)">
          {{ tag.title }}
        </v-chip>
      </div>
      <div class="text-center">
        <outlined-button darkContent @click-submit="clickAllProjects">
          <template #buttonText>
            {{ $t('start.project.buttonAll') }}
          </template>
        </outlined-button>
      </div>
    </v-col>
  </autoadjusting-row>
</template>

<script>
import AutoadjustingRow from '../partial/AutoadjustingRow.vue';
import OutlinedButton from '../partial/OutlinedButton.vue';
import TextComponent from '../partial/TextComponent.vue';
import {
  mapActions,
  mapGetters
} from 'vuex';
import resetProjectFiltersMixin from '../../mixins/resetProjectFiltersMixin.js';

//noinspection JSAnnotator
export default {
  name: 'ProjectLinks',
  components: {
    TextComponent,
    AutoadjustingRow,
    OutlinedButton,
  },
  mixins: [resetProjectFiltersMixin],
  data() {
    return {
      categoriesActive: true,
      servicesActive: false,
    };
  },
  computed: {
    ...mapGetters({
      projectCategories: 'project/projectCategories',
      projectTags: 'project/projectTags',
    }),
    catsAndTagsLoaded() {
      return this.projectCategories.length > 0 && this.projectTags.length > 0;
    },
    activeTags() {
      return this.categoriesActive ? this.projectCategories : this.projectTags;
    },
    getStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'max-height: 20px';
        case 'sm':
          return 'max-height: 18px';
        default:
          return 'max-height: 26px';
      }
    },
  },
  methods: {
    ...mapActions({
      loadProjectCategories: 'project/loadProjectCategories',
      loadProjectTags: 'project/loadProjectTags',
      updateProjectCategories: 'project/updateProjectCategories',
      updateProjectTags: 'project/updateProjectTags',
      setShowingProjectResults: 'project/setShowingProjectResults',
      setSelectedCategory: 'project/setSelectedCategory',
      setSelectedTag: 'project/setSelectedTag',
    }),
    showCategories(categories) {
      if (categories) {
        this.categoriesActive = true;
        this.servicesActive = false;
      } else {
        this.categoriesActive = false;
        this.servicesActive = true;
      }
    },
    clickAllProjects() {
      this.resetProjectFilters();
      this.$router.push({
        name: 'Project',
      });
    },
    setTagById(id) {
      this.setSelectedCategory(id);
      this.setShowingProjectResults(true);
      /*this.updateProjectCategories(arrayOfCats);
      this.updateProjectTags(arrayOfTags);
      */

      this.$router.push({
        name: 'Project',
      });
    },
  },
};
</script>
