<template>
  <div>
    <top-component addLogo :image-source="startpageImages.startHeroDesktop"
      :image-srcset="startpageImages.startPageMobile" scrollTo="#startScrollTo">
      <template #titleh2>{{ $t('start.hero.text') }}</template>
    </top-component>

    <two-cols id="startScrollTo">
      <template #first>
        <text-component dark-themed class="pt-16" position="left" :max-width="$vuetify.breakpoint.sm ? '329' : '523'">
          <template #titleh2>{{ $t('start.twoCol.title') }}</template>
        </text-component>

        <text-component dark-themed class="pb-8 pb-sm-16" position="left"
          :max-width="$vuetify.breakpoint.sm ? '329' : '404'">
          <template #content>{{ $t('start.twoCol.text1') }}</template>
        </text-component>
      </template>
      <template #second>
        <text-component dark-themed class="pt-0 pt-sm-16">
          <template #titleh3>{{ $t('start.twoCol.text2') }}</template>
          <template #contentWithoutFont>
            <table-component :items="services"></table-component>
          </template>
        </text-component>
        <text-component dark-themed class="pt-4 pb-sm-16">
          <template #content>
            <i18n path="start.twoCol.linkText" tag="p">
              <template v-slot:link>
                <a class="text-decoration-none" text href="om-incoord/verksamhet">{{ $t('start.twoCol.link') }}</a>
              </template>
            </i18n>
          </template>
        </text-component>
      </template>
    </two-cols>

    <srcset-image class="align-center low" :imageLarge="require('@/assets/images/start/desktop/infuture_bg.jpg')"
      :imageSmall="require('@/assets/images/start/mobile/infuture_bg.jpg')" :height="infutureImageHeight">
      <autoadjusting-row>
        <v-spacer> </v-spacer>
        <v-col cols="12" sm="8" md="8" lg="6">
          <text-component :centered="true" :dark-themed="false">
            <template #overline>{{ $t('about.infuture.overline') }}</template>
            <template #titleh2>{{ $t('about.infuture.title') }}</template>
            <template #content>{{ $t('about.infuture.text') }}</template>
            <template #actions>
              <outlined-button centered :darkContent="false" :to="{ name: 'InFuture' }">
                <template #buttonText>
                  {{ $t('about.infuture.button') }}
                </template>
              </outlined-button>
            </template>
          </text-component>
        </v-col>
        <v-spacer> </v-spacer>
      </autoadjusting-row>
    </srcset-image>

    <autoadjusting-row>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="8">
        <v-card flat class="text-center pt-15 pb-0 pb-lg-7 pt-lg-15">
          <h1 class="text-h1 text-center black--text">
            {{ $t('start.project.title1') }}
            <br />
            {{ $t('start.project.title2') }}
          </h1>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </autoadjusting-row>

    <featured-project v-if="featuredProject" :roundCorners="true" :featuredProject="featuredProject"></featured-project>

    <projects-links />

    <news-teaser subText>
      <template #newsTitle>
        <h3 class="text-h3 text-center white--text mb-4">
          {{ $t('news.teaser.overline') }}
          <br class="d-sm-none d-md-flex" />
        </h3>
        <h1 class="text-h1 text-center white--text">
          {{ $t('news.teaser.title') }}
          <br class="d-sm-none d-md-flex" />
        </h1>
      </template>
    </news-teaser>

    <mission-possible />

    <contact-us />
  </div>
</template>

<script>
import AutoadjustingRow from '../components/partial/AutoadjustingRow.vue';
import ContactUs from '../components/complete/ContactUs.vue';
import MissionPossible from '../components/complete/MissionPossible.vue';
import OutlinedButton from '../components/partial/OutlinedButton.vue';
import TextComponent from '../components/partial/TextComponent.vue';
import TopComponent from '../components/complete/TopComponent.vue';
import TwoCols from '../components/partial/TwoCols.vue';
import FeaturedProject from '../components/complete/FeaturedProject.vue';
import ProjectsLinks from '../components/complete/ProjectsLinks.vue';
import featuredProjectMixin from '../mixins/featuredProjectMixin.js';
import TableComponent from '../components/partial/TableComponent.vue';
import SrcsetImage from '../components/partial/SrcsetImage.vue';
import NewsTeaser from '../components/complete/ThreeTeasersNews.vue';
import setSubHeaderToNullMixin from '../mixins/setSubHeaderToNullMixin.js';
import { startpageImages } from '@/utils/constants';

export default {
  name: 'Start',
  components: {
    TwoCols,
    TextComponent,
    AutoadjustingRow,
    ContactUs,
    OutlinedButton,
    MissionPossible,
    TopComponent,
    FeaturedProject,
    ProjectsLinks,
    TableComponent,
    SrcsetImage,
    NewsTeaser,
  },
  mixins: [featuredProjectMixin, setSubHeaderToNullMixin],
  data() {
    return {
      startpageImages,
    };
  },
  created() {
    this.getFeaturedProject();
  },
  computed: {
    services() {
      return this.$t('start.twoCol.services').split('|');
    },
    infutureImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 516;
        case 'sm':
          return 290;
        default:
          return 496;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-application div.text-center>span.inactive {
  opacity: 0.6;
}

.low {
  max-height: 496px;
}
</style>
